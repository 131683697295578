import React from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Login from "./pages/Login";
import Logout from "./pages/Logout";
import Registration from "./pages/Registration";
import ProductList from "./pages/Product/ProductList";
import ProductCreate from "./pages/Product/ProductCreate";
import ProductEdit from "./pages/Product/ProductEdit";
import ProductShow from "./pages/Product/ProductShow";
import Test from "./pages/Test/Test";
import {MainContainer} from "./components/MainContainer";
import OrderList from "./pages/Order/OrderList";

const AppRouter = (items) => {
    return (
        <Router>
            <MainContainer>
                <Routes>
                    <Route exact path="/" element={<Login />} />
                    <Route exact path="/logout" element={<Logout />} />
                    <Route exact path="/signup" element={<Registration />} />
                    <Route exact path="/products" element={<ProductList />} />
                    <Route path="/products/create" element={<ProductCreate />} />
                    <Route path="/products/edit/:id" element={<ProductEdit />} />
                    <Route path="/products/show/:id" element={<ProductShow />} />

                    <Route exact path="/orders" element={<OrderList />} />
                    {/*<Route exact path="/test" element={<Test />} />*/}
                </Routes>
            </MainContainer>
        </Router>
    )
}

export default AppRouter;
