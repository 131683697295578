import React, {useCallback} from 'react';
import {createFieldValidator} from "../../../utils/create-field-validator";
import * as Yup from 'yup';
import {SearchDropdownField} from "../SearchDropdownField";

const defaultItems = [
    { value: 'Other', label: 'Other' },
];

const defaultLabel = 'Категорія';
const defaultPlaceholder = 'Категорія';

export const CategoryProductField = ({...props}) => {
    const { label = defaultLabel, placeholder = defaultPlaceholder, ...rest } = props;
    const items = props?.items.length ? props.items : defaultItems
    const validate = useCallback(createFieldValidator(Yup
        .number()
        .required('Це поле є обовязковим.')
    ), []);

    return (
        <SearchDropdownField {...{ ...rest, label, placeholder, items, validate }} />
    );
};

// const { id, name, label, placeholder, items} = SearchDropdownField.propTypes;
// CategoryProductField.propTypes = {
//     id,
//     name,
//     label,
//     placeholder,
//     items
// };
