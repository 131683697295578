import React, {useCallback, useMemo} from 'react';
import PropTypes from 'prop-types';
import { Input } from '../Input';
import { createFieldValidator } from '../../../utils/create-field-validator';
import * as Yup from "yup";

export const TextRequiredField = ({ validate: extValidate, ...props }) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const validate = useCallback(createFieldValidator(Yup
        .string()
        .min(2, 'Мінімально 2 символи')
        .max(100, 'Максимально 100 символів')
        .required('Це обов’язкове поле!')
    ), []);

    return (
        <Input type="text" {...{ ...props, validate }} />
    );
};

// const { id, name, label, autoFocus, placeholder, onHoverChange } = Input.propTypes;
// TextRequiredField.propTypes = {
//     id,
//     name,
//     label,
//     autoFocus,
//     placeholder,
//     onHoverChange,
//     validate: PropTypes.oneOfType([
//         PropTypes.func,
//         PropTypes.object
//     ]),
// };
