import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from "react-router-dom";
import axios from 'axios';
import {HeaderNav} from "../../components/HeaderNav";


function ProductShow() {
    const navigate = useNavigate();
    const [id, setId] = useState(useParams().id)
    const [product, setProduct] = useState({ name: '', description: '' })

    const axiosInstance = axios.create({
        baseURL: 'https://api.trueflex.com.ua/api',
    });
    axiosInstance.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token');

    useEffect(() => {
        if (localStorage.getItem('token') == null) {
            navigate("/");
        }

        axiosInstance.get(`/product/${id}`)
            .then(function (response) {
                setProduct(response.data)
            })
            .catch(function (error) {
                console.log(error);
            })
    }, [])

    return (
        <div className="crm__product-show">
            <HeaderNav title="Товари" />
            <div className="container">
                <div className="card mt-4">
                    <div className="card-header">
                        <Link
                            className="btn btn-outline-info float-right"
                            to="/products">Повернутись назад
                        </Link>
                    </div>
                    <div className="card-body">
                        <b className="text-muted">Name:</b>
                        <p>{product.name}</p>
                        <b className="text-muted">Content:</b>
                        <p>{product.content}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProductShow;