import React, { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom"
import Swal from 'sweetalert2'
import axios from 'axios'
import {HeaderNav} from "../../components/HeaderNav";
import {GoPlus} from "react-icons/go";
import {ButtonAdd} from "../../components/ButtonAdd/ButtonAdd";
import {Form, Formik} from "formik";
import Product from "../../api/Product";
import {MdEdit} from "react-icons/md";
import {FaRegTrashAlt} from "react-icons/fa";
import './OrderList.scss';
import {ButtonList} from "../../components/ButtonList/ButtonList";
import {ListPagination} from "../../components/ListPagination";
import {SubmitContext} from "../../context/SubmitContext";
import {ListFilter} from "../../components/ListFilter";
import {SearchField} from "../../components/field-components/SearchField";
import {RiFilterLine} from "react-icons/ri";

function OrderList() {
    const navigate = useNavigate();
    const [productList, setProductList] = useState([])
    const [pagination, setPagination] = useState({})
    const [productEdit, setProductEdit] = useState({})

    const [numberPages, setNumberPages] = useState( {'label': '5', 'value': 5})

    const [modalIsOpen, setIsOpen] = useState(false);

    useEffect(() => {
        if (localStorage.getItem('token') == null) {
            navigate("/");
        }
    }, []);

    const axiosInstance = axios.create({
        baseURL: 'https://api.trueflex.com.ua/api',
    });
    axiosInstance.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token');

    const fetchOrderList = (searchText) => {
        let params = {};
        if(searchText) params.search = searchText;
        axiosInstance.get('/order', {params})
            .then(function (response) {
                setProductList(response.data.data);
            })
            .catch(function (error) {
                console.log(error);
            })
    }

    const handleDelete = (id) => {
        Swal.fire({
            title: 'Ви впевнені?',
            text: "Ви не зможете скасувати це!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Так, видалити!',
            cancelButtonText: 'Ні',
        }).then((result) => {
            if (result.isConfirmed) {
                axiosInstance.delete(`/product/${id}`)
                    .then(function (response) {
                        Swal.fire({
                            icon: 'success',
                            title: 'Товар успішно видалено!',
                            showConfirmButton: false,
                            timer: 1500
                        })
                        fetchOrderList()
                    })
                    .catch(function (error) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Виникла помилка!',
                            showConfirmButton: false,
                            timer: 1500
                        })
                    });
            }
        })
    }


    function openModal() {
        setProductEdit({})
        setIsOpen(true);
    }

    const getOrders = async (data = {}) => {
       // let newData = {...data, 'per_page' : numberPages.value}
        const result = await Product.getProducts(data).then(function (response) {
            setProductList(response.data.data);
            setPagination(response.data.pagination);
        });
    }

    useEffect( () => {
        //getOrders()
    }, [numberPages]);

    const [resultSubmit, setResultSubmit] = useState({'success' : null});

    const onSubmit = async (values) => {
        await getOrders(values)
    };

    const defaultInitialValues = {
        search: '',
        per_page: 5,
        page: 1,
        status: '',
    };

    const initialValues = {
        ...defaultInitialValues
    }

    const formikOptions = {
        initialValues,
        onSubmit,
        validateOnChange: false,
        validateOnBlur: false,
    };

    return (
        <div>
            <HeaderNav title="Замовлення">
                <ButtonAdd onClick={openModal}>
                    <GoPlus size="18px"/>
                </ButtonAdd>
            </HeaderNav>

            <SubmitContext.Provider
                value={{
                    resultSubmit,
                    setResultSubmit
                }}
            >
                <Formik {...formikOptions}>
                    {({isSubmitting, isValid, dirty}) => (
                        <Form>
                            <ListFilter>
                                <SearchField type="text" name="search"></SearchField>
                            </ListFilter>
                            <div className="crm__item-list">
                                <div className="crm__item-list-table-wrapper">
                                    <table className="crm__item-list-table">
                                        <thead>
                                        <tr>
                                            <th>№</th>
                                            <th>Статус</th>
                                            <th>Телефон</th>
                                            <th>Покупець</th>
                                            <th>Продавець</th>
                                            <th>Оплата</th>
                                            <th width="122px"></th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {productList.map((item, key) => {
                                            return (
                                                <tr key={key}>
                                                    <td>{item.id}</td>
                                                    <td>{item.active ? 'Отримано' : 'Видалено'}</td>
                                                    <td>{item.vendor_code}</td>
                                                    <td>Дмитро</td>
                                                    <td>Бігун Владислав</td>
                                                    <td>Не оплачено</td>
                                                    <td>
                                                        <ButtonList>
                                                            <MdEdit />
                                                        </ButtonList>
                                                        <ButtonList onClick={() => handleDelete(item.id)}>
                                                            <FaRegTrashAlt/>
                                                        </ButtonList>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <ListPagination pagination={pagination}/>
                        </Form>
                    )}
                </Formik>
            </SubmitContext.Provider>

        </div>
    );
}

export default OrderList;