import React, { useMemo } from 'react';
import * as Yup from 'yup';
import { createFieldValidator } from '../../../utils/create-field-validator';

import './PriceField.scss';
import {NumberField} from "../NumberField";

const defaultLabel = 'Ціна';
const defaultPlaceholder = '0';

export const PriceField = (props) => {
    const { label = defaultLabel, placeholder = defaultPlaceholder, ...rest } = props;
    const validate = useMemo(() => createFieldValidator(Yup
        .number()
        .min(1, 'Не може бути меньше 0')
        .required('Обовязкове поле!.')
    ), []);

    return (
        <NumberField {...{ ...rest, validate, label, placeholder }}/>
    );
};

// const { id, name, label, placeholder, autoFocus, onHoverChange } = NumberField.propTypes;
// PriceField.propTypes = {
//     id,
//     name,
//     label,
//     placeholder,
//     autoFocus,
//     onHoverChange,
//     validate: PropTypes.func,
// };
