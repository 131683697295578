import React, { useEffect } from 'react'
import { useNavigate } from "react-router-dom"

function Login() {
    const navigate = useNavigate();
    useEffect(() => {
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        navigate("/");
    }, [])

    return (
        <div>Logout..</div>
    );
}

export default Login;