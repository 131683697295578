import React, {useCallback, useContext, useState} from 'react';
import { createFieldValidator } from '../../../utils/create-field-validator';
import * as Yup from "yup";
import {isFunction, useField} from "formik";
import InputMask from "react-input-mask";
import cx from "classnames";
import {Collapsible} from "../../Collapsible";
import {FormGroup} from "../FormGroup";
import {SubmitContext} from "../../../context/SubmitContext";
import debounce from "lodash/debounce";
import {noop} from "../../../utils/noop";
import {FaArrowsRotate} from "react-icons/fa6";
import './SlugField.scss';
import PropTypes from "prop-types";
import slugify from "slugify";

const defaultLabel = 'ЦПУ';
const defaultPlaceholder = '';

export const SlugField = ({ label = defaultLabel, mask, onHoverChange = noop, info, className, ...props }) => {

    const [isValidating, setIsValidating] = useState(false);
    const [lasValidatedValue, setLastValidatedValue] = useState();
    const [postValidationResult, setPostValidationResult] = useState();
    const [hasFocus, setHasFocus] = useState(false);

    const { id, name, type, placeholder = defaultPlaceholder, autoFocus, donorField } = props;
    // Do not pass validate to useField(). We'll use it internally to avoid running validation for all fields every time
    const { postValidate, ...fieldProps } = props;
    const [field, { error, touched }, { setError, setTouched, setValue}] = useField(fieldProps);
    const [fieldName] = useField(donorField);

    const { value, onBlur: fieldOnBlur} = field;

    const {resultSubmit, setResultSubmit} = useContext(SubmitContext);

    const validate = useCallback(createFieldValidator(Yup
        .string()
        .min(3, 'Мінімально 3 символи')
        .max(100, 'Максимально 100 символів')
        .required('Це обов’язкове поле!')
    ), []);

    const validation = async (validate, value, postValidate, lasValidatedValue, error, setError) => {
        setHasFocus(false);
        setTouched(true);
        let result;
        if (isFunction(validate)) {
            try {
                result = await validate(value);
            }
            catch (error) {
                result = error?.message ?? String(error);
            }
        }
        if (result != null) {
            setPostValidationResult(null);
        } else if (isFunction(postValidate)) {
            if (lasValidatedValue === value) {
                result = error;
            } else {
                setIsValidating(true);
                setLastValidatedValue(value);
                setPostValidationResult(await postValidate(value, (err) => result = err));
                setIsValidating(false);
            }
        }
        setError(result);
    }
    const submitChange = () => {
        setResultSubmit({'success' : null});
    }

    const debounceValidation = useCallback(debounce(validation, 2000), [])
    const debounceSubmitChange = useCallback(debounce(submitChange, 2000), [])
    const onInput = useCallback((event) => {
        if(resultSubmit.success !== null) debounceSubmitChange();
        debounceValidation(validate, event.target.value, postValidate, lasValidatedValue, error, setError);
    }, [validate, value, postValidate, lasValidatedValue, error, setError])

    const onBlur = useCallback(async (...args) => {
        fieldOnBlur(...args);
        debounceValidation?.cancel();
        // Fire original onBlur to inform formik about this event
        // Note that we can't fire it at the end of async function since this causes an error!

        await validation(validate, value, postValidate, lasValidatedValue, error, setError);

    }, [validate, postValidate, setError, fieldOnBlur, value, lasValidatedValue, error]);

    const onMouseEnter = useCallback(() => onHoverChange(true), [onHoverChange]);
    const onMouseLeave = useCallback(() => onHoverChange(false), [onHoverChange]);
    const onFocus = useCallback(() => setHasFocus(true), []);

    const onSlugify = async () => {
        let newValue = slugify(fieldName.value, {lower: true});
        setValue(newValue);
        await validation(validate, newValue, postValidate, lasValidatedValue, error, setError);
    }

    const inputWrapperClasses = cx(
        'crm__slug-input__wrapper',
        {
            'crm__slug-input__wrapper--error': touched && !isValidating && (error != null || postValidationResult != null),
            'crm__slug-input__wrapper--success': touched && !isValidating && error == null && postValidationResult == null,
            'crm__slug-input__wrapper--validating': isValidating,
        }
    );

    const inputClasses = cx(
        'lcz-input',
        { 'crm__slug-input--error': touched && !isValidating && error != null && (postValidationResult == null || typeof postValidationResult === 'boolean') },
        { 'crm__slug-input--success': touched && !isValidating && error == null && postValidationResult == null },
        { 'crm__slug-input-non-unique': !isValidating && postValidationResult != null && typeof postValidationResult !== 'boolean' },
        { 'crm__slug-input--validating': isValidating },
    );

    return (
        <FormGroup error={!isValidating && touched && postValidationResult == null ? error : null}
                   {...{ label, id, name, info, className, hasFocus, isValidating }}
        >
            <span className="crm__slug-input__container">
                <div className="crm__slug-input-flex">
                    <span className={inputWrapperClasses}>
                    <InputMask className={inputClasses} disabled={isValidating}
                               {...field}
                               {...{
                                   id: name,
                                   type,
                                   placeholder,
                                   mask,
                                   onMouseEnter,
                                   onMouseLeave,
                                   autoFocus,
                                   onFocus,
                                   onBlur,
                                   onInput,
                               }}
                    />
                    <div className={cx('crm__slug-input__spinner', { 'crm__slug-input__spinner--show': isValidating })}/>
                </span>
                <button className="crm__slug-input-button" type="button" onClick={onSlugify}>
                    <FaArrowsRotate />
                </button>
                </div>
                <Collapsible className="crm__slug-input__validation-result" duration={250}>
                    {(hasFocus || isValidating || typeof postValidationResult === 'boolean') ? null : postValidationResult}
                </Collapsible>
            </span>
        </FormGroup>
    );
};

SlugField.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    type: PropTypes.string.isRequired,
    mask: PropTypes.string,
    onHoverChange: PropTypes.func,
    autoFocus: PropTypes.bool,
    validate: PropTypes.func,
    postValidate: PropTypes.func,
    info: PropTypes.node,
    className: PropTypes.string,
};
