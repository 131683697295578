import React from 'react'
import AppRouter from "./AppRouter";
import Assets from "./Assets";

function App() {
  return (
      <Assets>
          <AppRouter/>
      </Assets>
  );
}

export default App;