import axios from "axios";
import {SignupRequestError} from "./SignupRequestError";

const endpoint = 'https://api.trueflex.com.ua/api/product/category';

const processResponse = (response) => {
    const { data } = response;
    if (!data) {
        throw new Error('Unknown response from server');
    } else if (data.success !== true) {
        throw new SignupRequestError(data.error);
    }
    return data;
};

export default class ProductCategory {
    static async getCategories() {
        return await axios.get(endpoint, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        });
    }
}