import React, {useContext, useEffect, useState} from 'react';
import './ListFilter.scss';
import {RiFilterLine} from "react-icons/ri";
import {ListFilterModal} from "../ListFilterModal";
export const ListFilter = ({children}) => {
    const [modalIsOpen, setIsOpen] = useState(false);
    function openModal() {
        setIsOpen(true);
    }
    return (
        <div className="crm__item-list-filter">
            <div className="tf-form__row">
                <div className="tf-form__col w50">
                    {children}
                </div>
                <div className="tf-form__col w50">
                    <button type="button" onClick={openModal}>
                        <RiFilterLine />
                    </button>
                </div>
            </div>
            <ListFilterModal modalIsOpen={modalIsOpen} setIsOpen={setIsOpen}/>
        </div>
    );
};
